<template>
  <div>
    <NuxtLayout>
      <NuxtPage /> 
    </NuxtLayout>
  </div>
</template>

<script lang="ts" setup>
import moment from 'moment-timezone';

const route = useRoute()

if (moment().isBetween(moment.tz('2025-01-04 07:50:00', 'Asia/Taipei'), moment.tz('2025-01-04 10:00:00', 'Asia/Taipei'))) {
  setPageLayout('maintenance')
}

// 2025/02/15（六）08:00~2025/02/15（六）09:00，SQL Server 主機系統重啟，進行停機。
if (moment().isBetween(moment.tz('2025-02-15 07:55:00', 'Asia/Taipei'), moment.tz('2025-02-15 09:00:00', 'Asia/Taipei'))) {
  setPageLayout('maintenance')
}

// 如果路徑開頭是 /admin/ 就切換成 admin layout
if (route.fullPath.match(/^\/admin\//)){
  setPageLayout('admin')
}
</script>

<style lang="stylus">
// mobile 選單打開時，鎖住背景
html.no-scroll
  overflow hidden !important  
</style>